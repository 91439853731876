import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAccountDatasetsForSubscriptionUser } from "./services/subscription-datasets.service";
import { Auth } from "./helpers";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() =>
    sessionStorage.getItem("AUTH_TOKEN")
  );

  const [datasets, setDatasets] = useState([]);

  const login = (userToken) => {
    sessionStorage.setItem("AUTH_TOKEN", userToken);
    setToken(userToken);
  };

  const logout = () => {
    sessionStorage.clear();
    setToken(null);
  };

  const getDatasets = async () => {
    const data = await getAccountDatasetsForSubscriptionUser();

    console.log(data);
    if (!data) {
      return;
    } else {
      setDatasets(data);
    }
  };

  useEffect(() => {
    if (token) {
      if (Auth.getUserPrivileges().includes("SUBSCRIPTION_ONLY")) {
        getDatasets();
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ token, login, logout, datasets, getDatasets }}
    >
      {children}
    </AuthContext.Provider>
  );
};
