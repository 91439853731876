import {
  Box,
  Drawer,
  Fab,
  IconButton,
  Pagination,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import SubscriptionSearchQueryForm from "./SubscriptionSearchQueryForm";
import LeftArrow from "../../assets/icons/left_arrow.svg";
import SearchIcon from "../../assets/icons/search.svg";
import RightArrow from "../../assets/icons/right_arrow.svg";
import AppSelect from "../../Components/AppSelect/AppSelect";
import DppaModal from "../../Components/DppaModal/DppaModal";
import useTable from "../../Components/useTable";
import { columns } from "./columns";
import TableLoader from "../../Components/TableLoader/TableLoader";
import "./SubscriptionSearchResults.css";

const SubscriptionSearchResults = ({ controller }) => {
  const { title, tab, results, query, openDetails, loading, updateData } =
    controller;

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [dppaModal, setDppaModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    columns[query.dataset.type].map((e) => e.id)
  );

  const tabContentRef = useRef();

  const { TblContainer, TblHead, setHeaders } = useTable(
    results,
    query?.dataset?.type &&
      columns[query.dataset.type].filter((e) => selectedColumns.includes(e.id))
  );

  useEffect(() => {
    if (query?.dataset?.type)
      setSelectedColumns(columns[query.dataset.type].map((e) => e.id));
  }, [query.dataset.type]);

  useEffect(() => {
    setHeaders(
      query?.dataset?.type &&
        columns[query.dataset.type].filter((e) =>
          selectedColumns.includes(e.id)
        )
    );
  }, [selectedColumns]);

  const downloadCSV = (onlySelected) => {
    const data = results;

    function convertToCSV() {
      const csvRows = [];

      // Get the headers (column names)
      const headers = onlySelected
        ? columns[query.dataset.type]
            .filter((e) => selectedColumns.includes(e.id))
            .map((e) => e.id)
        : columns[query.dataset.type].map((e) => e.id);
      const headerLabels = headers.map(
        (e) => columns[query.dataset.type].find((c) => c.id === e).label
      );

      csvRows.push(headerLabels.join(",")); // Join headers with commas

      // Add each row of data
      for (const row of data) {
        const values = headers.map((header) => {
          // Ensure to handle any commas in the data by enclosing the data in quotes
          const escaped = ("" + row[header]).replace(/"/g, '""');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(",")); // Join values with commas
      }

      // Join rows with newline character
      return csvRows.join("\n");
    }

    const csvContent = convertToCSV();
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", `${title}.csv`);
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {dppaModal && <DppaModal onClose={() => setDppaModal(false)} />}
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          minHeight: "70vh",
        }}
        ref={tabContentRef}
      >
        <div className="container-fluid px-4">
          <div className="row row-cols-auto gap-2 justify-content-center mt-3">
            <div className="col">
              <div
                style={{
                  maxWidth: 150,
                }}
              >
                <AppSelect
                  label="Columns"
                  className="mb-4"
                  value={
                    selectedColumns && selectedColumns.length > 0
                      ? selectedColumns
                      : []
                  }
                  options={
                    query && query.dataset && query.dataset.type
                      ? columns[query.dataset.type].map((e) => {
                          return { id: e.id, initials: e.label };
                        })
                      : []
                  }
                  onChange={(e) => {
                    if (e.target.value !== "all")
                      setSelectedColumns([...e.target.value]);
                  }}
                  onChangeSelectAll={(e) => {
                    setSelectedColumns(e);
                  }}
                  setNameForView={true}
                  editDetails={true}
                  allowMulti={true}
                />
              </div>
            </div>
            <div className="col">
              <UncontrolledButtonDropdown>
                <DropdownToggle
                  className="text-start"
                  style={{ borderRadius: "0.375rem" }}
                >
                  Export Data
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    disabled={!results || (results && results.length === 0)}
                    onClick={() => {
                      downloadCSV();
                    }}
                  >
                    Export All Columns
                  </DropdownItem>
                  <DropdownItem
                    disabled={
                      !results ||
                      (results && results.length === 0) ||
                      selectedColumns.length === 0
                    }
                    onClick={() => {
                      downloadCSV(true);
                    }}
                  >
                    Export Selected Columns
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="col">
              <Button onClick={toggleDrawer}>Edit Search Query</Button>
            </div>
          </div>
          {query?.dataset?.type && (
            <div className="container-fluid pb-2">
              {results && results.length > 0 && (
                <div
                  className="row row-cols-auto gap-2 justify-content-end"
                  style={{
                    color: "grey",
                    fontSize: 12,
                  }}
                >
                  <div className="col">
                    <b>Showing Records -</b>{" "}
                    {loading ? (
                      <div className="loading">
                        <div className="bar"></div>
                      </div>
                    ) : results && results.length > 0 ? (
                      `${(tab.currentPage - 1) * tab.resultsPerPage + 1} - ${
                        tab.currentPage * tab.resultsPerPage > tab.totalResults
                          ? tab.totalResults
                          : tab.currentPage * tab.resultsPerPage
                      }`
                    ) : (
                      "0 - 0"
                    )}
                  </div>
                  |
                  <div className="col">
                    <b>Total Records -</b>{" "}
                    {loading ? (
                      <div className="loading">
                        <div className="bar"></div>
                      </div>
                    ) : (
                      `${tab.totalResults || 0}`
                    )}
                  </div>
                </div>
              )}
              <div className="row">
                <div
                  className="col"
                  style={{ maxHeight: "650px", overflow: "scroll" }}
                >
                  <TblContainer>
                    <TblHead
                      handleSort={async (id, sortBy) => {
                        if (
                          loading ||
                          !results ||
                          (results && results.length === 0)
                        )
                          return;
                        await updateData({
                          query,
                          pagination: {
                            currentPage: tab.currentPage,
                            sortBy: id,
                            sortOrder: sortBy,
                          },
                        });
                      }}
                    />
                    {loading ? (
                      <TableLoader
                        columnsNumber={selectedColumns.length}
                        rows={tab.resultsPerPage || 10}
                      />
                    ) : results && results.length > 0 ? (
                      <TableBody>
                        {results.map((result, i) => {
                          return (
                            <TableRow
                              sx={{
                                "&:hover": {
                                  backgroundColor: "lightgrey", // Change background color on hover
                                  cursor: "pointer", // Optional: Change cursor on hover
                                },
                              }}
                              onClick={() => {
                                openDetails({
                                  details: {
                                    ...result,
                                    type: query.dataset.type,
                                  },
                                });
                              }}
                            >
                              {columns[query.dataset.type].map((e) => {
                                if (selectedColumns.includes(e.id))
                                  return (
                                    <TableCell width={e.width || "auto"}>
                                      {result[e.id] || "-"}
                                    </TableCell>
                                  );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <></>
                    )}
                  </TblContainer>
                </div>
              </div>
              {results && results.length > 0 && (
                <div className="row row-cols-auto justify-content-between align-items-center mt-3">
                  <div className="col">
                    <Pagination
                      showFirstButton
                      showLastButton
                      color="secondary"
                      shape="rounded"
                      count={Math.ceil(tab.totalResults / tab.resultsPerPage)}
                      page={tab.currentPage}
                      onChange={async (e, value) => {
                        if (tab.totalResults > 0)
                          await updateData({
                            query,
                            pagination: {
                              currentPage: value,
                            },
                          });
                      }}
                    />
                  </div>
                  <div className="col">
                    <AppSelect
                      value={tab.resultsPerPage || 10}
                      options={[
                        { id: 10, initials: "10 Records" },
                        { id: 20, initials: "20 Records" },
                        { id: 30, initials: "30 Records" },
                      ]}
                      onChange={async (e) => {
                        await updateData({
                          query,
                          pagination: {
                            resultsPerPage: e.target.value,
                            currentPage: 1,
                          },
                        });
                      }}
                      editDetails={true}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* Drawer */}
        <div>
          {drawerOpen && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                zIndex: 100, // Ensures it is above other elements but below the Drawer
              }}
            />
          )}
          <Drawer
            anchor="right"
            variant="persistent"
            open={drawerOpen}
            onClose={toggleDrawer}
            PaperProps={{
              className: "drawer-paper",
              style: {
                position: "absolute",
                visibility: dppaModal ? "hidden" : "visible",
              },
            }}
          >
            <SubscriptionSearchQueryForm
              controller={controller}
              closeDrawer={toggleDrawer}
              toggleDppa={() => setDppaModal((prev) => !prev)}
            />
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSearchResults;
