import baseUrl from "./interceptor";

export const getAccountDatasetsForSubscriptionUser = async () => {
  return await baseUrl.get(`subscriptions/admin/user-subscription-datasets`);
};

export const getAllSubscriptionDatasetsForSuperAdmin = async () => {
  return await baseUrl.get(`subscriptions/admin/subscription-datasets`);
};

export const getSubscriptionLicensesForTenantAdmin = async () => {
  return await baseUrl.get(`subscriptions/admin/account-subscription-license`);
};

export const createSubscriptionDatasetForSuperAdmin = async (dataset) => {
  return await baseUrl.post(`subscriptions/admin/subscription-datasets`, {
    ...dataset,
  });
};

export const getSubscriptionLicensesForTenantAccount = async (
  accountNumber
) => {
  return await baseUrl.get(
    `subscriptions/admin/subscription-license?accountNumber=${accountNumber}`
  );
};

export const updateSubscriptionLicensesForTenantAccount = async (
  accountNumber,
  subscriptionLicenses
) => {
  return await baseUrl.post(
    `subscriptions/admin/account-subscription-license`,
    {
      accountNumber: accountNumber,
      dataSetSeats: { ...subscriptionLicenses },
    }
  );
};

export const bulkAssignDatasetsToUser = async (email, dataSetIds) => {
  return await baseUrl.post(`subscriptions/admin/bulk-assign-datasets`, {
    dataSetIds: [...dataSetIds],
    emails: [email],
  });
};

export const bulkAssignUsersToDataset = async (emails, dataSetId) => {
  return await baseUrl.post(`subscriptions/admin/bulk-assign-users`, {
    dataSetIds: [dataSetId],
    emails: [...emails],
  });
};

export const getSingleSubscriptionDataset = async (datasetId) => {
  return await baseUrl.get(
    `subscriptions/admin/subscription-dataset?datasetId=${datasetId}`
  );
};

export const generateUploadUrlsForChunks = async (
  datasetId,
  fileName,
  partCount
) => {
  return await baseUrl.post(
    `subscriptions/admin/subscription-datasets/upload-urls`,
    {
      datasetId,
      fileName,
      partCount,
    }
  );
};

export const finalizeDatasetUploadBySuperAdmin = async (
  datasetId,
  completedParts
) => {
  return await baseUrl.post(
    `subscriptions/admin/subscription-datasets/complete-upload`,
    {
      datasetId,
      completedParts,
    }
  );
};
