import { Divider, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import RightArrow from "../../assets/icons/right_arrow.svg";
import AppInput from "../../Components/AppInput/AppInput";
import { Button } from "reactstrap";
import { getListOfUsers } from "../../Utils/services/accounts.service";
import { convertTZ } from "../../shared/constants/constants";
import dayjs from "dayjs";
import IconAppInput from "../../Components/IconAppInput/IconAppInput";
import searchIcon from "../../assets/icons/small_search.png";
import InfiniteScroll from "react-infinite-scroll-component";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { bulkAssignUsersToDataset } from "../../Utils/services/subscription-datasets.service";
import { ToastrService } from "../../Utils/services/tostr.service";

const AssignUsers = ({ selectedDataset, closeDrawer, onSave }) => {
  const [dataset, setDataset] = useState(selectedDataset);

  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);

  const fetchListOfUsers = async (resetData = false) => {
    setloader(true);
    try {
      let response = await getListOfUsers({
        sortDirection: order,
        sortBy: orderBy,
        pageNumber: page,
        size: 15,
        searchBy: searchQuery,
      });
      if (response.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach((item) => {
            const date = convertTZ(item.onBoardedDate);
            item.onBoardedDate = dayjs(date).format("MM-DD-YYYY");
          });
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setIsLoading(false);
      } else {
        setloader(false);
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setIsLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchListOfUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, searchQuery]);

  const addNewData = () => {
    setPage(page + 1);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await bulkAssignUsersToDataset(
      [...dataset.assigned_seats.map((e) => e.email)],
      dataset.id
    );
    await onSave();
    ToastrService.success("Users Assigned Successfully!");
    closeDrawer();
    setIsLoading(false);
  };

  if (!dataset) {
    return <></>;
  }

  return (
    <div
      className="container-fluid px-0 d-flex flex-column vh-100"
      style={{ width: "100%" }}
    >
      <AppLoader isLoading={isLoading} />
      <div
        className="pageTitle section-title text-center"
        style={{ background: "white" }}
      >
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <div>Assign Users ({dataset.name})</div>
            </div>
            <div className="col-auto">
              <Tooltip title="Cancel">
                <IconButton color="primary" onClick={closeDrawer}>
                  <img src={RightArrow} width={20} alt="Close" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="container">
        <div className="row px-3 py-2">
          <span
            className="text-muted d-flex justify-content-evenly fw-bold"
            style={{ fontSize: 13 }}
          >
            <span>Total Seats: {dataset.total_seats}</span>
            <span>Assigned Seats: {dataset.assigned_seats.length}</span>
            <span>
              Available Seats:{" "}
              {dataset.total_seats - dataset.assigned_seats.length}
            </span>
          </span>
        </div>
      </div>
      <Divider />
      <div className="px-3 pt-3">
        <IconAppInput
          label="Search..."
          icon={searchIcon}
          placeholder="Search by Name/Email ID"
          onEnter={(value) => {
            if (searchQuery !== value) {
              setData([]);
              setTotalRecords(0);
              setPage(0);
              setSearchQuery(value);
            }
          }}
        />
      </div>
      <div className="flex-grow-1 overflow-auto px-3 d-flex flex-column gap-2">
        <InfiniteScroll
          className="userListTable"
          dataLength={data.length}
          next={() => addNewData()}
          height="100%"
          hasMore={data.length < totalRecords}
          endMessage={
            !loader &&
            !data.length && (
              <p className="text-secondary mt-3 text-center">No Result Found</p>
            )
          }
        >
          {data.length > 0 &&
            data.map((user) => {
              if (
                user.isActive &&
                (dataset.total_seats - dataset.assigned_seats.length > 0 ||
                  (dataset.total_seats - dataset.assigned_seats.length === 0 &&
                    dataset.assigned_seats
                      .map((e) => e.email)
                      .includes(user.email)))
              )
                return (
                  <label
                    htmlFor={user.email}
                    className="d-flex align-items-start bg-light my-2 p-2 rounded"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="me-2">
                      <input
                        type="checkbox"
                        id={user.email}
                        className="form-check-input"
                        checked={dataset.assigned_seats
                          .map((e) => e.email)
                          .includes(user.email)}
                        onClick={(e) => {
                          setDataset((prev) => {
                            return {
                              ...prev,
                              assigned_seats: e.target.checked
                                ? [
                                    ...prev.assigned_seats,
                                    {
                                      firstName: user.firstName,
                                      lastName: user.lastName,
                                      email: user.email,
                                    },
                                  ]
                                : prev.assigned_seats.filter(
                                    (seat) => seat.email !== user.email
                                  ),
                            };
                          });
                        }}
                      />
                    </div>
                    <div>
                      <div className="fw-bold" style={{ cursor: "pointer" }}>
                        <div>
                          {user.firstName} {user.lastName}
                        </div>
                        <div className="fw-normal text-muted">{user.email}</div>
                      </div>
                    </div>
                  </label>
                );
            })}
        </InfiniteScroll>
      </div>
      <Divider />
      <div className="p-3 d-flex justify-content-center">
        <Button className="mx-2" style={{ width: "100%" }} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AssignUsers;
