import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./AppSelect.css";

export default function AppSelect(props) {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueArr, setSelectedValueArr] = useState([]);
  const [error, setError] = useState("");
  const [selectAllChange, setSelectAllChange] = useState(true);

  const {
    label,
    options,
    className,
    onChange,
    disabled,
    value,
    errorMsg,
    required,
    editDetails = false,
    allowMulti = false,
    addForm = false,
    onChangeSelectAll,
    setNameForView = false,
  } = props;

  useEffect(() => {
    if (editDetails) {
      if (Array.isArray(value)) {
        editDetails && setSelectedValueArr(value);
      } else {
        editDetails && setSelectedValue(value);
      }
    } else {
      setSelectedValueArr([]);
      setSelectedValue("");
    }
  }, [editDetails, addForm, value]);

  useEffect(() => {
    const selectedAll = selectedValueArr?.length === options?.length;
    if (selectedAll) {
      setSelectAllChange(false);
    } else {
      setSelectAllChange(true);
    }
  }, [selectedValueArr, options]);

  const handleValidation = (event) => {
    if (required) {
      if (event?.target && event?.target?.value === "") {
        setError(`${label} is required.`);
      } else {
        setError("");
      }
    }
  };

  const handleChange = (event) => {
    onChange(event);
    const { value } = event?.target;
    if (allowMulti) {
      if (value?.includes("all")) {
        const isSelectedAll = selectedValueArr?.length === options?.length;
        const selectedValues = isSelectedAll
          ? []
          : options?.map((option) => option?.id);
        setSelectedValueArr(selectedValues);
        onChangeSelectAll(selectedValues);
      } else {
        const newSelectedValues = selectedValueArr.includes(value)
          ? selectedValueArr.filter((val) => val !== value)
          : value;
        setSelectedValueArr(newSelectedValues);
      }
    } else {
      setSelectedValue(value);
    }
    handleValidation();
  };

  const calculateTop = () => {
    if (Array.isArray(value)) {
      return value.length && editDetails
        ? "0 !important"
        : selectedValueArr.length
        ? "0 !important"
        : "-8px !important";
    }
    return value && editDetails
      ? "0 !important"
      : selectedValue
      ? "0 !important"
      : "-8px !important";
  };

  const setFontSize = () => {
    if (Array.isArray(value)) {
      return "14px !important";
    }
    return "14px !important";
  };

  return (
    <FormControl className={`selectContainer ${className ? className : ""}`}>
      <InputLabel
        id="demo-simple-select-helper-label"
        className={disabled ? "disabledLabel" : ""}
        sx={{
          color: "#6c7985",
          fontSize: setFontSize(),
          fontFamily: '"Open sans" !important',
          lineHeight: "22px",
          paddingRight: "3px",
          top: calculateTop(),
          background: "white",
          "&.Mui-focused ": {
            paddingRight: "3px",
            color: "#673ab7",
            background: "white",
            top: "0 !important",
            fontSize: "17px",
            fontFamily: '"Open sans" !important',
          },
        }}
      >
        {label} {required ? "*" : ""}
      </InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        className={`customSelectField ${
          error !== "" || (errorMsg && errorMsg !== "") ? "selectError" : ""
        }`}
        id={"demo-simple-select-helper"}
        value={allowMulti ? selectedValueArr : selectedValue}
        label={label}
        onChange={handleChange}
        onBlur={handleValidation}
        sx={{
          height: "38px",
          background: "white",
          fontSize: "14px !important",
          color: "#323840",
          "&.Mui-disabled": {
            background: "#F8F8F8",
            color: "#323840",
            WebkitTextFillColor: "#323840",
          },
        }}
        disabled={disabled}
        multiple={allowMulti}
      >
        {allowMulti && (
          <MenuItem
            // value={selectAllChange ? "selectall" : "deselectall"}
            value="all"
            onClick={() => setSelectAllChange(!selectAllChange)}
          >
            {selectAllChange ? "Select All" : "Deselect All"}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            sx={{
              display: "block !important",
              padding: "6px 16px !important",
              "&.Mui-selected": {
                background: "#673ab7",
                color: "#fff",
                borderBottom: "1px solid #eae6d4",
                "&:hover": {
                  color: "#000",
                },
              },
            }}
            key={option.id}
            value={
              allowMulti ? option.id : setNameForView ? option.name : option.id
            }
          >
            {allowMulti
              ? option.initials
              : setNameForView
              ? option.name?.toString().replace(/_/g, " ")
              : option.initials}
          </MenuItem>
        ))}
      </Select>
      {(error || errorMsg) && (
        <FormHelperText>{errorMsg || error}</FormHelperText>
      )}
    </FormControl>
  );
}
