import { domainName } from "../shared/constants/constants";
import store from "./CommonStore";

let subDomainName; // = "stark";

// eslint-disable-next-line no-unused-vars
let storeSubscription = store.subscribe(() => {
  let name = store.getState().subDomain.subDomain;
  if (name !== "" && name !== domainName) {
    subDomainName = name;
  }
});

//get JWT token auth
const getAuthToken = () => {
  const token = sessionStorage.getItem("AUTH_TOKEN");
  return token && token !== "" ? token : null;
};

//get tenant auth
const getTenant = () => {
  const tenant = sessionStorage.getItem("tenant");
  return tenant && tenant !== "" ? tenant : null;
};

//get account number
const getAccountNumber = () => {
  const accountNumber = sessionStorage.getItem("accountNumber");
  return accountNumber && accountNumber !== "" ? accountNumber : null;
};

//get user role
const getUserRole = () => {
  const role = sessionStorage.getItem("role");
  return role && role !== "" ? JSON.parse(role) : null;
};

// get sub domain name
const getSubDomainName = () => {
  return subDomainName;
};

// get user email
const getUserEmail = () => {
  const email = sessionStorage.getItem("email");
  return email && email !== "" ? email : null;
};

// get is batch search allowed flag
const isBatchSearchAllowedFlag = () => {
  const batchSearchAllowFlag = sessionStorage.getItem("isBatchSearchAllowed");
  return batchSearchAllowFlag === "true";
};

// get is primary user flag
const isPrimaryAdminFlag = () => {
  const isPrimaryAdminFlag = sessionStorage.getItem("isPrimaryAdmin");
  return isPrimaryAdminFlag === "true";
};

//get user privileges
const getUserPrivileges = () => {
  const privileges = sessionStorage.getItem("privileges");
  return privileges ? JSON.parse(privileges) : null;
};

//methods Export
export const Auth = {
  getAuthToken,
  getTenant,
  getAccountNumber,
  getUserRole,
  getSubDomainName,
  getUserEmail,
  isBatchSearchAllowedFlag,
  isPrimaryAdminFlag,
  getUserPrivileges,
};
