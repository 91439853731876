import React, { useEffect, useState } from "react";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Chip,
  Drawer,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Button } from "reactstrap";
import useTable from "../../Components/useTable";
import { columns } from "./columns";
import editIcon from "../../assets/icons/edit-2-outline.svg";
import AssignUsers from "./AssignUsers";
import {
  getAllSubscriptionDatasetsForSuperAdmin,
  getSubscriptionLicensesForTenantAdmin,
} from "../../Utils/services/subscription-datasets.service";
import { Auth } from "../../Utils/helpers";

const data = [
  {
    id: 0,
    name: "Dataset 1",
    state: "ID",
    type: "MVR",
    year: "2021",
    version: "Q4",
    createdAt: "01/01/2021",
    createdBy: "John Doe",
    available_licenses: 13,
    status: "draft",
  },
  {
    id: 1,
    name: "Dataset 2",
    state: "ID",
    type: "MVR",
    year: "2021",
    version: "Q4",
    createdAt: "01/01/2021",
    createdBy: "John Doe",
    available_licenses: 13,
    status: "processing",
  },
  {
    id: 2,
    name: "Dataset 3",
    state: "ID",
    type: "DL",
    year: "2021",
    version: "Q3",
    createdAt: "01/01/2021",
    createdBy: "John Doe",
    available_licenses: 1,
    status: "success",
  },
];

const SubscriptionDatasets = () => {
  useDocumentTitle("Subscription Datasets");

  const history = useHistory();

  const [assignUsersDrawer, setAssignUsersDrawer] = useState();
  const [datasets, setDatasets] = useState();

  const isTenantAdmin = Auth.getUserRole().includes("COMPANY_ADMIN");

  const filteredColumns = columns.filter((e) => {
    if (isTenantAdmin && e.tenantAdmin) {
      return e;
    } else if (!isTenantAdmin && !e.hideInAdmin) {
      return e;
    }
  });

  const { TblContainer, TblHead } = useTable(null, filteredColumns);

  const getDatasets = async () => {
    let data = [];
    if (!isTenantAdmin) {
      const res = await getAllSubscriptionDatasetsForSuperAdmin();
      if (res) {
        data = res;
      } else {
      }
    } else {
      const res = await getSubscriptionLicensesForTenantAdmin();
      if (res && res.subscriptions) {
        res.subscriptions.forEach((dataset) => {
          data.push({
            id: dataset.dataSet.id,
            name: dataset.dataSet.name,
            state: dataset.dataSet.state,
            type: dataset.dataSet.type,
            year: dataset.dataSet.year,
            version: dataset.dataSet.version,
            createdAt: dataset.dataSet.createdAt,
            createdBy: dataset.dataSet.createdBy,
            total_seats: dataset.maxSeatCount,
            assigned_seats: dataset.assignedSeats,
            available_seats:
              dataset.maxSeatCount - dataset.assignedSeats.length,
            status: dataset.status,
          });
        });
      } else {
      }
    }

    setDatasets(data);
  };

  useEffect(() => {
    getDatasets();
  }, []);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <div className="pageTitle">Subscription Datasets</div>
        {!isTenantAdmin && (
          <div className="accountListIcons">
            <Tooltip title="Add Subscription Dataset">
              <div
                className="smallAddIcon"
                onClick={() => {
                  history.push("/account/subscription/dataset");
                }}
              ></div>
            </Tooltip>
          </div>
        )}
      </div>
      {datasets && datasets.length === 0 && (
        <div className="text-danger">
          No Dataset available. Contact WWI Admin to add datasets to your
          account!
        </div>
      )}
      <div className="row mt-4">
        <Drawer
          open={assignUsersDrawer}
          onClose={() => setAssignUsersDrawer()}
          anchor="right"
          PaperProps={{
            style: {
              width: "50%",
              padding: 0,
            },
          }}
        >
          <AssignUsers
            selectedDataset={assignUsersDrawer}
            closeDrawer={() => setAssignUsersDrawer()}
            onSave={async () => {
              getDatasets();
            }}
          />
        </Drawer>
        <TblContainer>
          <TblHead />
          <TableBody>
            {datasets &&
              datasets.length > 0 &&
              datasets.map((data) => {
                return (
                  <TableRow>
                    {filteredColumns.map((column) => {
                      if (column.id === "assigned_seats") {
                        return (
                          <TableCell>{data.assigned_seats.length}</TableCell>
                        );
                      }

                      if (column.id === "actions") {
                        if (isTenantAdmin) {
                          return (
                            <TableCell align="right">
                              <Button
                                onClick={() => {
                                  setAssignUsersDrawer(data);
                                }}
                              >
                                Assign Users
                              </Button>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell align="right">
                            {data["status"].toString().toLowerCase() ===
                            "draft" ? (
                              <Tooltip title="Continue Editing">
                                <img
                                  src={editIcon}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    history.push(
                                      "/account/subscription/dataset?id=" +
                                        data.id
                                    );
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        );
                      }

                      if (column.id === "status") {
                        return (
                          <TableCell>
                            <Chip
                              size="small"
                              label={data[column.id]}
                              style={{
                                textTransform: "uppercase",
                              }}
                              color={
                                data[column.id].toString().toLowerCase() ===
                                "available"
                                  ? "success"
                                  : data[column.id].toString().toLowerCase() ===
                                    "failed"
                                  ? "error"
                                  : data[column.id].toString().toLowerCase() ===
                                    "processing"
                                  ? "info"
                                  : "default"
                              }
                            />
                          </TableCell>
                        );
                      }

                      return <TableCell>{data[column.id] || "-"}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </TblContainer>
      </div>
    </div>
  );
};

export default SubscriptionDatasets;
