import React, { useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledButtonDropdown,
} from "reactstrap";
import AppLoader from "../../Components/AppLoader/AppLoader.jsx";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  styled,
  Tab,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import useTable from "../../Components/useTable.js";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Redirect } from "react-router-dom";
import useToken from "../../shared/hooks/useToken.jsx";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle.jsx";
import {
  downloadFile,
  getDownloadOnlyDetails,
} from "../../Utils/services/downloads.service.js";
import useSubDomainName from "../../shared/hooks/useSubDomainName.jsx";
import downloadIcon from "../../assets/icons/sidebar/download-icon.svg";
import closeIcon from "../../assets/icons/close 1.svg";
import { useFieldArray, useForm } from "react-hook-form";
import SubscriptionSearchResults from "./SubscriptionSearchResults.jsx";
import DppaModal from "../../Components/DppaModal/DppaModal.jsx";
import { columns } from "./columns.js";
import Details from "./Details.jsx";
import useLocalStorage from "use-local-storage";
import { toast } from "react-toastify";
import { handleSubscriptionSearch } from "../../Utils/services/subscription-search.service.js";
import AppTabs from "../../Components/AppTabs/AppTabs.jsx";
import { getAccountDatasetsForSubscriptionUser } from "../../Utils/services/subscription-datasets.service.js";
import AppTab from "../../Components/AppTabs/AppTab.jsx";
import { tabController } from "./TabController.js";
import { useAuth } from "../../Utils/AuthContext.js";

const SubscriptionSearch = () => {
  useDocumentTitle("Subscription Search");

  const { watch, register, setValue, control, reset } = useForm({
    defaultValues: {
      tabs: [],
      activeTab: null,
      closedTabs: [],
    },
  });

  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    getDatasets();
    console.log("test");
  }, []);

  const tabs = useFieldArray({
    name: "tabs",
    control,
    keyName: "id",
  });

  const onClickAddNewSearch = ({
    isAddressSearch = false,
    isSearch = true,
    type,
  }) => {
    tabs.append({
      isAddressSearch,
      isSearch,
      key: Math.random().toString(),
      query: {
        dataset: {
          type: type || "DL",
        },
      },
    });
  };

  const getDatasets = async () => {
    const data = await getAccountDatasetsForSubscriptionUser();

    if (!data) {
      return;
    } else {
      setDatasets(data);
    }
  };

  const onClickRemoveTab = (index) => {
    tabs.update(index, {
      ...tabs.fields[index],
      hidden: true,
    });

    setValue("closedTabs", [...watch("closedTabs"), index]);
  };

  const onClickUndoClose = () => {
    const closedTabs = watch("closedTabs");

    tabs.update(closedTabs[closedTabs.length - 1], {
      ...tabs.fields[closedTabs[closedTabs.length - 1]],
      hidden: false,
    });
    setValue("closedTabs", watch("closedTabs").slice(0, -1));
  };

  useEffect(() => {
    console.log("tab changed");
    if (tabs.fields.length > 0)
      setValue("activeTab", tabs.fields[tabs.fields.length - 1].key);
  }, [tabs.fields.length]);

  useEffect(() => {
    const nonHiddenFields = tabs.fields.filter((e) => !e.hidden);

    if (nonHiddenFields && nonHiddenFields.length > 0) {
      const closedTabIndex =
        watch("closedTabs")[watch("closedTabs").length - 1];
      const activeTabIndex = tabs.fields.findIndex(
        (e) => e.key === watch("activeTab")
      );
      const OpenTabsAfterTheClosedTab =
        tabs.fields.length > 0 && nonHiddenFields && nonHiddenFields.length > 0
          ? tabs.fields
              .slice(closedTabIndex, tabs.fields.length - 1)
              .filter((e) => !e.hidden)
          : null;
      const openTabAfterTheClosedTab =
        OpenTabsAfterTheClosedTab && OpenTabsAfterTheClosedTab.length > 0
          ? OpenTabsAfterTheClosedTab[0]
          : null;

      if (closedTabIndex === activeTabIndex && openTabAfterTheClosedTab) {
        setValue("activeTab", openTabAfterTheClosedTab?.key);
      } else {
        if (nonHiddenFields) {
          setValue(
            "activeTab",
            nonHiddenFields[nonHiddenFields.length - 1].key
          );
        }
      }
    }
  }, [watch("closedTabs")]);

  const { token } = useToken();

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="container-fluid row mb-2">
          <div className="row pageTitle">Subscription Search</div>
          {datasets && datasets.length === 0 && (
            <div className="text-danger">
              No Datasets available. Contact your admin to add datasets to your
              account
            </div>
          )}
          {datasets && datasets.length > 0 && (
            <>
              {" "}
              <div className="row">
                <div className="row row-cols-auto gap-2">
                  <div className="col container">
                    <Button
                      onClick={() =>
                        onClickAddNewSearch({
                          isAddressSearch: false,
                          isSearch: true,
                          type: "DL",
                        })
                      }
                    >
                      New Driver Search
                    </Button>
                  </div>
                  <div className="col container">
                    <Button
                      onClick={() =>
                        onClickAddNewSearch({
                          isAddressSearch: false,
                          isSearch: true,
                          type: "MVR",
                        })
                      }
                    >
                      New Vehicle Search
                    </Button>
                  </div>
                  <div className="col container">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        className="text-start"
                        style={{ borderRadius: "0.375rem" }}
                      >
                        New Address Search
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            onClickAddNewSearch({
                              isAddressSearch: true,
                              type: "DL",
                            });
                          }}
                        >
                          Driver
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            onClickAddNewSearch({
                              isAddressSearch: true,
                              type: "MVR",
                            });
                          }}
                        >
                          Vehicle
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                  {watch("closedTabs") && watch("closedTabs").length > 0 && (
                    <div className="col container">
                      <Button onClick={() => onClickUndoClose()}>
                        Re-Open{" "}
                        {
                          tabController(
                            tabs.fields[
                              watch("closedTabs")[
                                watch("closedTabs").length - 1
                              ]
                            ],
                            tabs,
                            null
                          ).title
                        }
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                {tabs && tabs.fields.length > 0 && (
                  <AppTabs
                    active={watch("activeTab")}
                    onTabChange={(value) => {
                      setValue("activeTab", value);
                    }}
                  >
                    {tabs.fields.map((tab, index) => {
                      const controller = tabController(tab, tabs, datasets);

                      return (
                        <AppTab
                          title={controller.title}
                          onClose={() => onClickRemoveTab(index)}
                          hidden={tab.hidden}
                          tabKey={tab.key}
                        >
                          {tab.isDetails ? (
                            <Details controller={controller} />
                          ) : (
                            <SubscriptionSearchResults
                              controller={controller}
                            />
                          )}
                        </AppTab>
                      );
                    })}
                  </AppTabs>
                )}

                {/* {tabs &&
              tabs.fields.length > 0 &&
              tabs.fields.filter((e) => !e.hidden).length > 0 && (
                <div>
                  <Nav
                    tabs
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      overflowX: "auto",
                    }}
                  >
                    {tabs.fields.map((tab, index) => {
                      if (tab.hidden) {
                        return;
                      }
                      const { title } = tabController(tab);

                      return (
                        <NavItem
                          key={tab.key}
                          style={{
                            padding: 0,
                            flex: "0 0 auto",
                          }}
                        >
                          <NavLink
                            className={
                              watch("activeTab") === tab.key
                                ? "active text-white"
                                : ""
                            }
                            style={{
                              background:
                                watch("activeTab") === tab.key && "#673ab7",
                            }}
                            active={watch("activeTab") === tab.key}
                            onClick={() => {
                              toggleTab(tab.key);
                            }}
                          >
                            {title}{" "}
                            <Tooltip title={`Close ${title}`}>
                              <IconButton
                                onClick={() => onClickRemoveTab(index)}
                                style={{ color: "white" }}
                              >
                                <img
                                  src={closeIcon}
                                  width={10}
                                  style={{
                                    filter:
                                      watch("activeTab") === tab.key &&
                                      "invert(1)",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>

                  <TabContent activeTab={watch("activeTab")}>
                    {tabs.fields.map((tab, index) => {
                      if (tab.hidden) {
                        return;
                      }
                      const controller = tabController(tab);

                      return (
                        <TabPane
                          tabId={tab.key}
                          style={{
                            background: "white",
                          }}
                        >
                          {tab.isDetails ? (
                            <Details controller={controller} />
                          ) : (
                            <SubscriptionSearchResults
                              controller={controller}
                            />
                          )}
                        </TabPane>
                      );
                    })}
                  </TabContent>
                </div>
              )} */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionSearch;
