import AccountsList from "../Pages/AccountsList/AccountsList.jsx";
import AddAccount from "../Pages/AddAccount/AddAccount.jsx";
import UserList from "../Pages/UserList/UserList.jsx";
import AddUser from "../Pages/AddUser/AddUser.jsx";
import SearchHome from "../Pages/SearchHome/SearchHome.jsx";
import BatchSearch from "../Pages/BatchSearch/BatchSearch.jsx";
import DownloadOnly from "../Pages/DownloadOnly/DownloadOnly.jsx";
import searchIcon from "../assets/icons/sidebar/search-outline.svg";
import addUserIcon from "../assets/icons/sidebar/person-add-outline.svg";
import usersListIcon from "../assets/icons/sidebar/people-outline.svg";
import fileIcon from "../assets/icons/sidebar/file-icon.svg";
import documentsSearchIcon from "../assets/icons/sidebar/documents-search.svg";
import batchHistoryIcon from "../assets/icons/sidebar/batch-history.svg";
import searchHistoryIcon from "../assets/icons/sidebar/history-line.svg";
import menuIcon from "../assets/icons/sidebar/menu-2-outline.svg";

import { privileges, roles } from "../shared/constants/constants.js";
import SingleSearchHistory from "../Pages/SingleSearchHistory/SingleSearchHistory.jsx";
import BatchSearchHistory from "../Pages/BatchSearchHistory/BatchSearchHistory.jsx";
import SearchReport from "../Pages/SearchReport/SearchReport.jsx";
import downloadIcon from "../assets/icons/sidebar/download-icon.svg";
import uploadIcon from "../assets/icons/sidebar/upload-icon.svg";
import UploadFiles from "../Pages/UploadFiles/UploadFiles.jsx";
import SubscriptionSearch from "../Pages/SubscriptionSearch/SubscriptionSearch.jsx";
import SubscriptionDatasets from "../Pages/SubscriptionDatasets/SubscriptionDatasets.jsx";
import SubscriptionDataset from "../Pages/SubscriptionDataset/SubscriptionDataset.jsx";

var AuthenticatedRoute = [
  {
    id: 1,
    name: "Search",
    component: SearchHome,
    childrenPaths: ["/search", "/batchSearch"],
    icon: searchIcon,
    privilege: [privileges.single_search, privileges.batch_search],
    children: [
      {
        id: 2,
        path: "/search",
        name: "Single Search",
        component: SearchHome,
        icon: searchIcon,
        privilege: privileges.single_search,
      },
      {
        id: 3,
        path: "/batchSearch",
        name: "Batch Search",
        component: BatchSearch,
        icon: documentsSearchIcon,
        privilege: privileges.batch_search,
      },
      {
        id: 17,
        name: "Subscription Search",
        component: SubscriptionSearch,
        path: "/subscription/search",
        icon: searchIcon,
        privilege: privileges.subscription_only,
        roles: [roles.admin, roles.non_admin],
      },
    ],
  },
  {
    id: 4,
    name: "History",
    component: SingleSearchHistory,
    childrenPaths: ["/history", "/batchHistory"],
    icon: fileIcon,
    privilege: [privileges.single_search, privileges.batch_search],
    children: [
      {
        id: 5,
        path: "/history",
        name: "Single Search History",
        component: SingleSearchHistory,
        icon: searchHistoryIcon,
        privilege: privileges.single_search,
      },
      {
        id: 6,
        path: "/batchHistory",
        name: "Batch Search History",
        component: BatchSearchHistory,
        icon: batchHistoryIcon,
        privilege: privileges.batch_search,
      },
    ],
  },
  {
    id: 7,
    name: "Accounts",
    component: AccountsList,
    childrenPaths: ["/list", "/add"],
    icon: usersListIcon,
    privilege: [privileges.list_account, privileges.add_account],
    children: [
      {
        id: 8,
        path: "/list",
        name: "Accounts List",
        component: AccountsList,
        icon: usersListIcon,
        privilege: privileges.list_account,
      },
      {
        id: 9,
        path: "/add",
        name: "Add Account",
        component: AddAccount,
        icon: addUserIcon,
        privilege: privileges.add_account,
      },
      {
        id: 10,
        name: "Upload Files",
        path: "/uploads",
        component: UploadFiles,
        icon: uploadIcon,
        privilege: privileges.upload_files,
      },
    ],
  },
  {
    id: 11,
    name: "Users",
    component: UserList,
    childrenPaths: ["/userList", "/addUser"],
    icon: usersListIcon,
    privilege: [privileges.list_user, privileges.add_user],
    children: [
      {
        id: 12,
        path: "/userList",
        name: "Users List",
        component: UserList,
        icon: usersListIcon,
        privilege: privileges.list_user,
      },
      {
        id: 13,
        path: "/addUser",
        name: "Add User",
        component: AddUser,
        icon: addUserIcon,
        privilege: privileges.add_user,
      },
    ],
  },
  {
    id: 14,
    name: "Reports",
    component: SearchReport,
    childrenPaths: ["/reports"],
    icon: menuIcon,
    privilege: [privileges.billing],
    children: [
      {
        id: 15,
        path: "/reports",
        name: "Overall Search Count Report",
        component: SearchReport,
        icon: fileIcon,
        privilege: privileges.billing,
      },
    ],
  },
  {
    id: 16,
    name: "Download Files",
    component: DownloadOnly,
    path: "/downloads",
    icon: downloadIcon,
    privilege: privileges.subscription_only,
  },

  {
    id: 18,
    name: "Subscription Datasets",
    component: SubscriptionDatasets,
    childrenPaths: ["/subscription/datasets", "/subscription/dataset"],
    icon: batchHistoryIcon,
    privilege: privileges.subscription_only,
    roles: [roles.super_admin, roles.company_admin],
    children: [
      {
        id: 19,
        path: "/subscription/datasets",
        name: "Subscription Datasets List",
        component: SubscriptionDatasets,
        icon: batchHistoryIcon,
        privilege: privileges.subscription_only,
        roles: [roles.super_admin, roles.company_admin],
      },
      {
        id: 20,
        path: "/subscription/dataset",
        name: "Add Subscription Dataset",
        component: SubscriptionDataset,
        icon: fileIcon,
        privilege: privileges.add_account,
        roles: [roles.super_admin],
      },
    ],
  },
  {
    id: 0,
    path: "/account",
    pathTo: "/account/search",
    name: "Search",
    redirect: true,
  },
];
export default AuthenticatedRoute;
