import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { Chip, Divider, IconButton, styled, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Button } from "reactstrap";
import AppSelect from "../../Components/AppSelect/AppSelect";
import AppInput from "../../Components/AppInput/AppInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  createSubscriptionDatasetForSuperAdmin,
  finalizeDatasetUploadBySuperAdmin,
  generateUploadUrlsForChunks,
  getSingleSubscriptionDataset,
} from "../../Utils/services/subscription-datasets.service";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { ToastrService } from "../../Utils/services/tostr.service";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&::before": {
    display: "none",
  },
  borderRadius: 10,
  marginBottom: 10,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: 10,
  fontSize: 26,
  color: "#673ab7",
  fontWeight: "bold",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function SubscriptionDataset(props) {
  useDocumentTitle("Subscription Dataset");

  const { watch, register, setValue, getValues, handleSubmit, reset } = useForm(
    {
      defaultValues: {
        currentPanel: "dataset-details",
        loading: false,
      },
    }
  );

  const history = useHistory();

  const handleDataset = async () => {
    const uploaded = await handleDatasetUpload();
    if (uploaded) history.push("/account/subscription/datasets");
  };

  const handleCreateSubscriptionDataset = async () => {
    const dataset = await createSubscriptionDatasetForSuperAdmin(
      watch("dataset")
    );
    if (dataset) {
      ToastrService.success("Dataset Created Successfully!");
      history.push("/account/subscription/dataset?id=" + dataset);
    }
  };

  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const id = query.get("id");

    if (id) {
      setValue("id", id);
      fetchSubscriptionDataset(id);
    } else {
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleNext = () => {
    switch (watch("currentPanel")) {
      case "dataset-details":
        {
          if (!watch("id")) handleCreateSubscriptionDataset();
          setValue("currentPanel", "upload-dataset");
        }
        break;
      case "upload-dataset":
        {
          setValue("currentPanel", "review");
        }
        break;
      default: {
        return;
      }
    }
  };

  const fetchSubscriptionDataset = async (id) => {
    setValue("loading", true);
    const dataset = await getSingleSubscriptionDataset(id);
    if (dataset) {
      setValue("dataset", {
        state: dataset.state,
        type: dataset.type,
        year: dataset.year,
        version: dataset.version,
        name: dataset.name,
      });
    }
    setValue("loading", false);
  };

  const handleDatasetUpload = async () => {
    const file = watch("dataset.file")[0];
    if (!file) {
      ToastrService.error("Please select a file!");
      return;
    }
    setValue("isUploading", true);
    setValue("uploadingMessage", "Starting Upload...");
    const fileName = file.name;
    const fileSize = file.size;

    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk
    const chunkCount = Math.ceil(fileSize / CHUNK_SIZE);

    const presignedUrls = await generateUploadUrlsForChunks(
      watch("id"),
      fileName,
      chunkCount
    );

    if (!presignedUrls) {
      setValue("isUploading", false);
      setValue("uploadingMessage", null);
      ToastrService.error("Something went wrong!");
      return;
    }

    setValue("uploadingMessage", "Uploading...");

    // Step 3: Upload each chunk using presigned URLs
    const uploadedParts = await Promise.all(
      Array.from({ length: chunkCount }, async (v, partNumber) => {
        const start = partNumber * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, fileSize);
        const chunk = file.slice(start, end);

        const uploadResponse = await fetch(presignedUrls[partNumber], {
          method: "PUT",
          body: chunk,
        });

        if (uploadResponse.ok) {
          const etag = uploadResponse.headers.get("ETag");
          return {
            eTag: etag,
            partNumber: partNumber + 1, // part numbers are 1-indexed
          };
        } else {
          throw new Error(`Failed to upload part ${partNumber + 1}`);
        }
      })
    );

    // Optional: Handle any errors that occurred during uploads
    const successfulUploads = uploadedParts.filter(Boolean); // Filter out any undefined values from failed uploads

    if (successfulUploads.length !== chunkCount) {
      ToastrService.error("Failed to upload.");
      return;
    } else {
      setValue("uploadingMessage", "Finalizing Upload...");

      const finalizeUpload = await finalizeDatasetUploadBySuperAdmin(
        watch("id"),
        uploadedParts
      );

      setValue("isUploading", false);
      setValue("uploadingMessage", null);

      // if (finalizeUpload) {
      ToastrService.success("Upload successful!");
      return true;
      // } else {
      //   ToastrService.error("Something went wrong!");
      //   return;
      // }
    }
  };

  return (
    <>
      <AppLoader
        isLoading={watch("loading") || watch("isUploading")}
        message={watch("isUploading") ? watch("uploadingMessage") : null}
      />
      <div className="container-fluid">
        <div className="pageTitle">
          {watch("id") ? "Edit " : ""}Subscription Dataset
        </div>
        <div className="text-muted">{watch("dataset.name")}</div>
        <Divider className="mt-3 mb-3" />
        <form onSubmit={handleSubmit(handleDataset)}>
          <div className="pt-4">
            <Accordion expanded={watch("currentPanel") === "dataset-details"}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                onClick={() => {
                  setValue("currentPanel", "dataset-details");
                }}
              >
                1. Dataset Details
              </AccordionSummary>
              <AccordionDetails>
                <div className="container-fluid">
                  <div className="row p-3 gap-2">
                    <div className="col">
                      <AppSelect
                        label="State"
                        className="mb-4"
                        value={watch("dataset.state")}
                        options={[
                          {
                            id: 1,
                            name: "ID",
                          },
                          {
                            id: 2,
                            name: "MA",
                          },
                        ]}
                        onChange={(e) =>
                          setValue("dataset.state", e.target.value)
                        }
                        setNameForView={true}
                        editDetails={true}
                        required
                      />
                    </div>
                    <div className="col">
                      <AppSelect
                        label="Type"
                        className="mb-4"
                        value={watch("dataset.type")}
                        options={[
                          {
                            id: 1,
                            name: "DL",
                          },
                          {
                            id: 2,
                            name: "MVR",
                          },
                        ]}
                        onChange={(e) =>
                          setValue("dataset.type", e.target.value)
                        }
                        setNameForView={true}
                        editDetails={true}
                        required
                      />
                    </div>
                    <div className="col">
                      <AppInput
                        type="number"
                        label="Year"
                        className="mb-4"
                        value={watch("dataset.year")}
                        onChange={(e) =>
                          setValue("dataset.year", e.target.value)
                        }
                        validations={{
                          required: true,
                        }}
                        required
                      />
                    </div>
                    <div className="col">
                      <AppInput
                        type="text"
                        label="Version"
                        className="mb-4"
                        value={watch("dataset.version")}
                        onChange={(e) => {
                          setValue("dataset.version", e.target.value);
                        }}
                        validations={{
                          required: true,
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      disabled={
                        !(
                          watch("dataset.state") &&
                          watch("dataset.type") &&
                          watch("dataset.year") &&
                          watch("dataset.version")
                        )
                      }
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={watch("currentPanel") === "upload-dataset"}
              disabled={!watch("id")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                onClick={() => {
                  setValue("currentPanel", "upload-dataset");
                }}
              >
                2. Upload Dataset
              </AccordionSummary>
              <AccordionDetails>
                <div className="container-fluid">
                  <div className="row p-3 gap-2">
                    <div className="col">
                      <div
                        id="draggableFileContainer"
                        className="batchSearchContainer"
                        onClick={(e) => {
                          if (!watch("file")?.name) {
                            document.getElementById("batchFileInput").click();
                          }
                        }}
                        style={{
                          height: "150px",
                          opacity: watch("file")?.name ? 0.5 : 1,
                          cursor: watch("file")?.name
                            ? "not-allowed"
                            : "pointer",
                        }}
                        // onDrop={handleFileDrop}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "150px",
                            width: "100%",
                          }}
                        >
                          <div className="batchSearchDivTitle">
                            <div className="smallUploadIconBatchSearch"></div>
                            <div className="batchSearchText">
                              <div className="batchSearchTextTitle">
                                Upload Dataset file
                              </div>
                              <div className="batchSearchSubTitle">
                                Only .psv file of max 2GB
                              </div>
                            </div>
                          </div>
                        </div>
                        <input
                          type="file"
                          accept=".psv,.csv,.tsv,.txt"
                          id="batchFileInput"
                          onClick={(e) => (e.currentTarget.value = "")}
                          onChange={(e) => {
                            setValue("dataset.file", e.target.files);
                          }}
                          hidden
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end align-items-center gap-2">
                    {watch("dataset.file") && (
                      <div className="fw-bold">
                        {watch("dataset.file")[0].name}
                      </div>
                    )}
                    {/* {watch("dataset.file") && !watch("dataset.uploaded") && (
                      <Button
                        onClick={() => {
                          if (
                            watch("dataset.file") &&
                            watch("dataset.file").length > 0
                          )
                            handleDatasetUpload(watch("dataset.file")[0]);
                        }}
                      >
                        Upload Dataset
                      </Button>
                    )} */}

                    <Button
                      onClick={handleNext}
                      disabled={!watch("dataset.file")}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={watch("currentPanel") === "review"}
              disabled={!watch("id") || !watch("dataset.file")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                onClick={() => {
                  setValue("currentPanel", "review");
                }}
              >
                3. Review
              </AccordionSummary>
              <AccordionDetails>
                <div className="container-fluid">
                  <div
                    className="row p-3 gap-2"
                    style={{ position: "relative" }}
                  >
                    <div className="col">
                      <AppSelect
                        disabled
                        label="State"
                        className="mb-4"
                        value={watch("dataset.state")}
                        options={[
                          {
                            id: 1,
                            name: "ID",
                          },
                          {
                            id: 2,
                            name: "MA",
                          },
                        ]}
                        onChange={(e) =>
                          setValue("dataset.state", e.target.value)
                        }
                        setNameForView={true}
                        editDetails={true}
                        required
                      />
                    </div>
                    <div className="col">
                      <AppSelect
                        disabled
                        label="Type"
                        className="mb-4"
                        value={watch("dataset.type")}
                        options={[
                          {
                            id: 1,
                            name: "DL",
                          },
                          {
                            id: 2,
                            name: "MVR",
                          },
                        ]}
                        onChange={(e) =>
                          setValue("dataset.type", e.target.value)
                        }
                        setNameForView={true}
                        editDetails={true}
                        required
                      />
                    </div>
                    <div className="col">
                      <AppInput
                        disabled
                        label="Year"
                        className="mb-4"
                        value={watch("dataset.year")}
                        editDetails={true}
                        required
                      />
                    </div>
                    <div className="col">
                      <AppInput
                        disabled
                        type="text"
                        label="Version"
                        className="mb-4"
                        value={watch("dataset.version")}
                        onChange={(e) => {
                          setValue("dataset.version", e.target.value);
                        }}
                        validations={{
                          required: true,
                        }}
                        required
                      />
                    </div>
                    <Chip
                      label="Edit"
                      color="warning"
                      size="small"
                      onClick={() => {
                        setValue("currentPanel", "dataset-details");
                      }}
                      style={{
                        cursor: "pointer",
                        width: "auto",
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    />
                  </div>
                  <div className="row row-cols-auto p-3 gap-2">
                    <div className="col">
                      <div className="container">
                        <div className="row row-cols-auto gap-2">
                          {watch("dataset.file") &&
                            watch("dataset.file").length > 0 && (
                              <div className="fw-bold">
                                Dataset File : {watch("dataset.file")[0].name}
                              </div>
                            )}
                          <Chip
                            label="Edit"
                            color="warning"
                            size="small"
                            onClick={() => {
                              setValue("currentPanel", "upload-dataset");
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button type="submit">Done</Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </form>
      </div>
    </>
  );
}
